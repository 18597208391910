.inputDni, .btn-dni{
    height: 50px;
}

.btn-dni{
    margin-top: 26px;
}

.error-msg{
    padding: 10px;
    background: #FA0F00;
    width: 100%;
    margin: 5px auto;
    text-align: center;
    color: #fff;
    font-weight: bold;
}