.content-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.btn-transparent, .btn-transparent:hover{
    background-color: transparent;
    color: #212529;
}

.p-error-login{
    padding: 10px;
    background: rgb(250, 15, 0);
    width: 100%;
    margin: 5px auto;
    text-align: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
}