.happ-color {
    color: #0050ff !important;
}

.container{
    max-width: 1450px !important;
}

.happ-background-color {
    background: #0050ff !important;
}

.white-color .nav-link, .white-color .navbar-brand{
    color: #fff;
}

.nav-link{
    margin-left: 20px;
    margin-right: 20px;
}

.row-nav{
    width: 100%;
    margin: 0;
}

.row-nav .col-12{
    padding: 0;
}

.row-nav .dropdown-menu{
    left: -60px;
}

.btn-outline, .btn-outline:hover {
    background-color: transparent;
    border: none;
    color: #212529;
}

.content-survey{
    padding: 20px;
    border: 2px solid rgba(224, 224, 224, 1);
    border-radius: 8px;
}

.col-question{
    border-bottom: 2px solid rgba(224, 224, 224, 1);
    padding: 20px;
}

.btn-survey{
    float: right;
}

.content-form{
    padding: 20px;
}

.content-form .row{
    border: 2px solid rgba(224, 224, 224, 1);
    padding: 15px;
    border-radius: 8px;
}

.col-question label{
    font-weight: 600;
}

.switch-imc{
    margin-top: 6px;
    padding-left: 5em;
}

.col-filter{
    display: flex;
}

.col-filter button{
    margin-left: 10px;
}

.btn-box{
    width: 100%;
}



*{
    font-size: 13px;
}

.content-spinner{
    text-align: center;
}

.col-table{
    margin: 0 3.333rem;
}

.btn-preview{
    height: 30px;
    padding: 0px 13px !important;
    margin: 0;
}

.btn-hidden{
    visibility: hidden;
}


.react-pdf__Page__canvas{
    margin: 0 auto !important;
}

.modal-exams .modal-dialog{
    max-width: 40% !important;
}

.actions-exam,
.content-btn-exam{
    display: flex;
    align-items: center;
}

.status-test, .origin-test{
    padding: 0px 5px 2px 5px;
    border-radius: 10px;
}

.origin-test{
    margin-right: 10px;
}

.divider{
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #cdcdcd;
}

.divider:last-child {
    border: none;
}

.div-actions{
    margin-right: 20px;

}

.btn-box .a-link{
    width: 100%;
    margin-top: 10px;
}

.float-right{
    float: right;
}

.origin-note{
    text-align: center  !important;
    padding: 15px 0px 15px 0px !important;
}
.origin-note h5{
    margin: 0;
}

.content-origin{
    margin-top: 10px !important;
}

.btn-update{
    width: 100%;
}

.strong-patologia{
    text-transform: capitalize;
}

.title-exam{
    display: flex;
    color: #044064;
    text-decoration: none;
}

.download-file{
    cursor: pointer;
    margin-left: 20px;
}

.origin-row{
    position: relative;
}

.origin-row span{
    position: absolute;
    right: 16px;
    border: 1px solid #000;
    padding: 0 6px 0px 6px;
    border-radius: 15px;
}

.content-check-info{
    margin-left: 100px;
    display: flex;
}

.content-check-info h6{
    margin-right: 20px;
}

.check-patient, .btn-save-observation{
    float: right;
}


.content-info-2{
    margin-left: 100px;
}
.margin-info{
    margin-left: 30px !important;
}

.margin-0{
    margin-left: 0 !important;
}

.content-info-2 div {
    display: flex;
}

.content-info div {
    display: flex;
    justify-content: end;
}

.content-info-2 div .form-check, .content-info .form-check{
    margin-left: 20px;
    margin-left: 15px;
}

.content-info-2 div .form-check .form-check-input, .content-info .form-check .form-check-input{
    margin-right: 6px;
}

.diag-comple{
    width: 37%;
}
.diag-comple a{
    background: #fff;
    color: #000;
    padding: 5px 10px 0px 10px;
    border: 1px solid #000;
    border-radius: 15px;
}

.error-group{
    float: left;
}

.error-group small{
    font-weight: bold;
    font-style: italic;
}

.error-group p{
    padding: 5px;
    background: #fff;
    border-bottom: 1px solid rgb(182, 28, 17);
    width: 100%;
    margin: 5px auto;
    font-style: italic;
    text-align: center;
    color: #000;
    font-weight: bold;
}

.list-group-item::first-letter{
    text-transform: uppercase;
}

.last-prescription-btn{
    float: right;
}

.success-save{
    text-align: center;
    float: left;
    padding: 5px 10px;
    background: #28a745;
    color: #fff;
    border-radius: 5px;
}
.error-save{
    text-align: center;
    float: left;
    padding: 5px 10px;
    background: #e62929;
    color: #fff;
    border-radius: 5px;
}
.error-msg{
    padding: 5px 10px;
    background: #e62929;
    color: #fff;
    border-radius: 5px;
}

.header-info-doc{
    justify-content: end;
}

.label-title{
    border-bottom: 1px solid #000;
    width: 100%;
}

.res-strong{
    float: right;
}
.res-yes-strong{
    float: right;
    color: #e62929;
    font-size: 20px;
}

.last-recipe{
    float: right;
}

.col-btn-delete{
    position: absolute;
    right: -30px;
    top: 31px;
}

.col-btn-delete svg{
    float: right;
}

.btn-delete{
    cursor: pointer;
}

.row-medicine{
    position: relative;
}

.invalid {
    border: 2px solid #ff0000 !important;
}

.refresh-icon{
    margin-right: 10px;
    margin-top: -2px;
}

.col-input{
    position: relative;
}

.p-error-profile-col{
    color: red;
    float: left;
    position: absolute;
    bottom: -21px;
}

.p-error-medical-agenda{
    text-align: center;
    /* float: left; */
    padding: 5px 10px;
    /* background: #28a745; */
    color: red;
    /* border-radius: 5px; */
    border-bottom: 1px solid #000;
}

.warnign-box{
    text-align: center;
    background: red;
    border-radius: 8px;
    color: #fff;
}

.title-physical-exams{
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid;
}

.btn-upload-file{
    display: flex;
    justify-content: center!important;
    line-height: 2.5;
}

.btn-upload-file svg{
    font-size: 20px;
    margin-top: 6px;
}

.btn-upload-file p{
    margin: 0 0 0 10px !important;
}

.file-name{
    position: absolute;
    top: 50px;
    font-weight: bold;
    width: 150px;
}

.image-relative{
    position: relative;
}

.select-search-input {
    position: relative;
    z-index: 1;
    display: block;
    height: 30.72px !important;
    width: 100%;
    padding: 0.375rem 0.75rem !important;
    background: var(--select-search-background);
    border: 2px solid var(--select-search-border);
    color: var(--select-search-text);
    border-radius: 0.375rem;
    outline: none;
    font-family: var(--select-search-font);
    font-size: 1rem;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 1.5 !important;
    letter-spacing: 0rem !important;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
}

.select-search-option, .select-search-not-found{
    font-size: 1rem !important;
}

.select-search-container{
    width: 100% !important;
}

.content-phone{
    display: flex;
}

.pre-phone{
    width: 31%;
    margin-right: 15px;
}

.phone{}

.p-error-pre{}

.btn-tp2{
    height: 50px;
    font-size: 18px;
    padding: 0px 15px;
}